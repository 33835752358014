<template>
  <section class="section section_0--menu">
    <div class="grid">
      <b-navbar toggleable="lg">
        <b-navbar-brand href="#">
          <h1 class="logo_name">ПРАВИТ ТЛТ</h1>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#" v-scroll-to="'.section_2--stages'">Оформление заказа</b-nav-item>
            <b-nav-item href="#" v-scroll-to="'.section_3--priceList'">Прайс-лист</b-nav-item>
            <b-nav-item href="#" v-scroll-to="'.section_5--footer'">Контакты</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="--social"> </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </section>
</template>

<script>


export default {
  name: "navbar",
};
</script>

<style lang="less">
@import "../styles/variables.less";

.section_0--menu {
  position: fixed !important;
  top: 0;
  width: 100%;
  padding: 0 !important;
  height: auto;
  z-index: 100;
  background: @color-header;

  #nav-collapse {
    justify-content: flex-end;

    li a {
      color: @color-white;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      &:hover {
        color: @color-orange;
      }
    }
  }
}
</style>
