<template>
  <section class="section section_5--footer">
    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A59cf5016f566e9c08a9332574beb16ed8ed971ba323adec52f6f5b54fa0bdbd7&amp;source=constructor" width="100%" height="550" frameborder="0"></iframe>

    <footer>
      <div class="grid">
        <div class="section__content--grid">
          <!-- <img src="@/assets/logo_white.png" alt="" class="logo" /> -->
          <h1 class="logo_name">ПРАВИТ ТЛТ</h1>
          <a href="#" class="title _sm opherta">Договор-оферта</a>
          <!-- <a href="#" class="title _sm politic">Политика конфиденциальности</a> -->
        </div>
      </div>
    </footer>

    <div class="item__absolute">
      <h3 class="title">Контактные данные</h3>
      <div class="line"></div>
      <a href="tel:+79878150777" class="text-800 text-content phone phone_1">
        +7{{ "\xa0" }}(987){{ "\xa0" }}815-07-77</a
      >
      <a href="tel:+79878150777" class="text-800 text-content phone phone_2"
        >+7{{ "\xa0" }}(987){{ "\xa0" }}815-07-77</a
      >
      <p class="text-content time">
        Пн-Пт, с{{ "\xa0" }}8{{ "\xa0" }}до{{ "\xa0" }}17
      </p>
      <a href="mailto:pravittlt1@yandex.ru" class="text-content mail"
        >pravittlt1@yandex.ru</a
      >
      <a
        href="https://yandex.ru/maps/?um=constructor%3A59cf5016f566e9c08a9332574beb16ed8ed971ba323adec52f6f5b54fa0bdbd7&source=constructorLink"
        class="text-content address"
        target="_blank"
        >Самарская область, г. Тольятти, ул. Ларина 145, офис{{ "\xa0" }}8</a
      >
      <button data-shiny-button class="shiny-button button">
        <a
          href="https://yandex.ru/maps/?um=constructor%3A59cf5016f566e9c08a9332574beb16ed8ed971ba323adec52f6f5b54fa0bdbd7&source=constructorLink"
          class="text-800 title _md"
          target="_blank"
          >Открыть в&nbsp;навигаторе</a
        >
        <!-- <canvas></canvas> -->
      </button>
    </div>
  </section>
</template>
<script>
export default {
  name: "footer",
};
</script>

<style lang="less">
@import "../styles/variables.less";
.section_5--footer {
  padding-bottom: 1em !important;
  .yandex_map {
    margin-bottom: 10em;
  }

  footer {
    margin-top: 3em;

    .section__content--grid {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
    }
  }

  .item__absolute {
    position: absolute;
    background: @color-grey;
    border-radius: 4px;
    max-width: 926px;
    height: 351px;
    width: 100%;
    bottom: -5%;
    left: 50%;
    transform: translate(-50%, -30%);
    padding: 3em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    gap: 20px 10px;
    grid-template-areas:
      "title title title"
      "line line line"
      "phone_1 time address"
      "phone_2 mail button";

    & > .title {
      grid-area: title;
      margin-bottom: 10px;
    }
    & > .line {
      grid-area: line;
    }
    & > .phone_1 {
      grid-area: phone_1;
    }
    & > .phone_2 {
      grid-area: phone_2;
    }
    & > .time {
      grid-area: time;
    }
    & > .mail {
      grid-area: mail;
    }
    & > .address {
      grid-area: address;
    }
    & > .button {
      grid-area: button;
      max-width: 100%;
    }

    .line {
      width: 100%;
      height: 1px;
      background: @color-white;
      margin-bottom: 1em;
    }
    .shiny-button {
      a:hover {
        color: inherit !important;
      }
    }
  }
}
</style>
